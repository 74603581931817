import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import { graphql, PageProps } from 'gatsby';
import PageBanner from '@/components/blog-hub-page-banner';
import BookOfFameSection from '@/components/book-of-fame-section';
import AwardsSection from '@/components/awards-section';
import WorkSlider from '@/components/work-slider-section';
import { getTitle } from '@/utils/helper/getTitle';

const Work = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbLabel="Work" crumbs={crumbs}>
      <Seo
        title={data?.sanityWorkPageMetadata?.work_page_metadata?.pageMetaName || 'Work'}
        description={data?.sanityWorkPageMetadata?.work_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityWorkPageMetadata?.work_page_metadata?.metadata}
        canonical={data?.sanityWorkPageMetadata?.work_page_metadata?.canonical}
      />
      <PageBanner
        page="WORK"
        title={{ solid: data?.sanityWorkBanner?.title }}
        imageUrl={data?.sanityWorkBanner?.book_image}
        isGradient={false}
        showProject={false}
        imageMargin={true}
        body={data?.sanityWorkBanner?._rawDescription}
        backgroundUrl
        downloadBookButton={data?.sanityWorkBanner?.download_book_button}
        getInTouchButton={data?.sanityWorkBanner?.get_in_touch}
        bannerImgClass={`rotate-15`}
        downloadButtonUrl={data?.sanityBookOfFame?.downloadable?.file?.asset?.url}
      />
      <div className="bg-white h-32 w-full"></div>
      <WorkSlider
        title={{
          ...getTitle(data?.sanityOurWorksSection),
          isInline: true,
        }}
        works={data?.allSanityOurWorks?.edges}
      />

      <BookOfFameSection
        title={getTitle(data?.sanityBookOfFame)}
        description={data?.sanityBookOfFame?.description}
        bookImageUrl={data?.sanityBookOfFame?.book_image}
        actionButton={data?.sanityBookOfFame?.download_button}
        downloadLink={data?.sanityBookOfFame?.downloadable?.file?.asset?.url}
      />
      <AwardsSection title={getTitle(data?.sanityAwards)} awardLogos={data?.sanityAwards?.awards} />
    </Layout>
  );
};

export default Work;

export const query = graphql`
  query {
    sanityWorkPageMetadata {
      work_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityWorkBanner {
      id
      title
      book_image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      _rawDescription
      download_book_button {
        url
        title
      }
      get_in_touch {
        url
        title
      }
    }
    allSanityOurWorks(sort: { fields: category___title }) {
      edges {
        node {
          id
          slug {
            current
          }
          title {
            title_solid
            title_outlined
            is_outline_first
          }
          excerpt
          case_study_title
          category {
            title
          }
          _rawBody
          banner {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          thumbnail {
            asset {
              gatsbyImageData(width: 350, placeholder: BLURRED)
            }
          }
        }
      }
    }

    sanityOurWorksSection {
      id
      title {
        title_solid
        is_outline_first
        title_outlined
      }
    }

    sanityAwards {
      id
      title {
        is_outline_first
        title_outlined
        title_solid
      }
      awards {
        image {
          asset {
            gatsbyImageData(width: 240, placeholder: BLURRED)
          }
        }
      }
    }

    sanityBookOfFame {
      book_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
      description
      title {
        title_outlined
        title_solid
        is_outline_first
      }
      download_button {
        title
        url
      }
      downloadable {
        file {
          asset {
            title
            url
          }
        }
      }
    }
  }
`;
