import React, { useEffect, useState } from 'react';
import { Container } from '@/components/_root';
import CaseStudiesSlider from '@/components/case-studies-slider';
import { IWork } from '@/utils/interface';
import filterWorks from '@/utils/helper/filter-works';
import SectionTitle from '../section-title';

interface Props {
  title: {
    solid?: string;
    outlined?: string;
    isInline?: boolean;
    className?: string;
  };
  works: IWork[];
}

const WorkSlider = ({ title, works }: Props): JSX.Element => {
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    works.forEach(({ node: work }) => {
      if (!categories.includes(work.category.title))
        setCategories([...categories, work.category.title]);
    });
  }, [categories, works]);

  return (
    <>
      <Container>
        <div className={`md:w-10/12`}>
          <SectionTitle title={title} isInline={title.isInline} />
        </div>
      </Container>
      <div className="pt-20 pb-32">
        {categories.map((category, index) => (
          <CaseStudiesSlider
            sectionTitle={{ solid: category, className: 'text-13' }}
            caseStudies={filterWorks(category, works)}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default WorkSlider;
